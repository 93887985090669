import { useState, useEffect } from 'react';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import ICategoryPage from '../../CategoryPage/Models/CategoryPageModel.interface';
import {
  InitCategoryItems,
  GetCategoryItems,
} from '../../Pages/SearchPage/Search';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import ProductCard from '../../Organisms/ProductCard/ProductCard';
import { useKexLoadingCircle } from '../../Kex/KexLoadingCircle';
import HeroModule from '../../Organisms/Hero/HeroModule';
import FullSearchResult from '../../Search/Models/FullSearchResult.interface';
import ProductCardModel from '../../ProductCard/Models/ProductCardModel.interface';
import useMedia from '../../Shared/Hooks/useMedia';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';
import Grid from '../../Atoms/Grids/Grid';
import LoadMoreContainer from '../../Molecules/LoadMoreContainer/LoadMoreContainer';
import { styled } from '../../stitches.config';
import { GridWidth } from '../../Enums/GridSize.enum';
import GridListToggle from '../../Molecules/ButtonGroups/GridListToggle';
import SearchSorter from '../../Search/Models/SearchSorter.interface';
import FilterBar from '../../Molecules/FilterBar/FilterBar';
import ContentArea from '../../Organisms/ContentArea/ContentArea';
import ProductSorter from '../../Molecules/ProductSorter/ProductSorter';
import CategoryBar from '../../Molecules/CategoryBar/CategoryBar';
import KexFacetGroupFilter from '../../Search/Models/KexFacetGroupFilter.interface';
import { StructuredCategoryData } from '../../Molecules/StructuredData/StructuredCategoryData';

function CategoryPage() {
  const { languageRoute, startPageId } = useAppSettingsData();

  const {
    'categoryPage/productCounter': productCounterLabel,
    'categoryPage/loadMore': loadMoreText,
    'categoryPage/noFilterResult': noFilterResultLabel,
    'categoryPage/clearFilter': clearFilterText,
  } = useTranslationData();

  const {
    teaserHeader,
    teaserHeaderSize,
    teaserButtonType,
    teaserImage,
    teaserLinks,
    teaserText,
    pageId,
    channelId,
    itemsPerLoad,
    contentAreaTop,
    contentAreaBottom,
  } = useCurrentPage<ICategoryPage>();
  const category = useCurrentPage<ICategoryPage>();

  const requestContext = JSON.stringify({
    currentPageSystemId: startPageId,
    categorySystemId: pageId,
    channelSystemId: channelId,
  });

  const dispatchLoading = useKexLoadingCircle();
  const [searchResult, setSearchResult] = useState<FullSearchResult>();
  const [listView, setListView] = useState(false);
  const [selectedSorter, setSelectedSorter] = useState<SearchSorter>();
  const [sorters, setSorters] = useState<SearchSorter[]>();
  const [filter, setFilter] = useState<string>();
  const isDesktop = useMedia(mediaQueryTypes.mediaMinHuge);
  const [facetFilters, setFacetFilters] = useState<KexFacetGroupFilter[]>();
  const [priceValue, setPriceValue] = useState<number[]>();

  useEffect(() => {
    initSearch();
  }, [pageId]);

  const initSearch = () => {
    dispatchLoading('add');

    const ignoredParams = ['page'];
    const unChangedParams = (window.location.search.substr(1) || '')
      .split('&')
      .filter((param) => {
        const [id, value] = param.split('=');
        return param.length > 0 && !ignoredParams.includes(id);
      });

    const querystring = [...unChangedParams].join('&');
    InitCategoryItems(
      pageId,
      itemsPerLoad,
      requestContext,
      languageRoute,
      querystring
    ).then((data: FullSearchResult) => {
      if (
        data &&
        data?.productSearchResult &&
        data?.productSearchResult?.numberOfItems > 0
      ) {
        setSearchResult(data);
        setSelectedSorter(
          data?.productSearchResult?.sorters.find(
            (x: SearchSorter) => x.selected
          )
        );
        setSorters(data?.productSearchResult?.sorters);
      } else {
        setSearchResult(data);
      }
      dispatchLoading('remove');
    });
  };

  const loaded = searchResult
    ? (searchResult?.productSearchResult?.items.length /
        searchResult?.productSearchResult?.availableItems) *
      100
    : 0;

  const counterText =
    searchResult && searchResult?.productSearchResult && productCounterLabel
      ? productCounterLabel
          .replace(
            '{0}',
            searchResult?.productSearchResult?.items.length.toString()
          )
          .replace(
            '{1}',
            searchResult?.productSearchResult?.availableItems.toString()
          )
      : '';

  const disabled =
    searchResult &&
    searchResult?.productSearchResult?.items.length ===
      searchResult?.productSearchResult?.availableItems;

  const fetchMoreItems = () => {
    const loadedItems = searchResult?.productSearchResult.items.length;
    const take = itemsPerLoad;

    if (searchResult) {
      dispatchLoading('add');
      GetCategoryItems(
        pageId,
        take,
        requestContext,
        languageRoute,
        searchResult,
        loadedItems,
        selectedSorter?.sortBy,
        selectedSorter?.sortDirection,
        filter
      ).then((data) => {
        if (data) {
          setSearchResult(data);
        }
        dispatchLoading('remove');
      });
    }
  };

  const onSorterChange = (
    sortBy: string,
    sortDirection: number,
    closeSorterDropdown: () => void
  ) => {
    closeSorterDropdown();
    if (searchResult != undefined) {
      dispatchLoading('add');
      GetCategoryItems(
        pageId,
        itemsPerLoad,
        requestContext,
        languageRoute,
        undefined,
        undefined,
        sortBy,
        sortDirection,
        filter
      ).then((data) => {
        setSearchResult(data);
        setSorters(data.productSearchResult?.sorters);
        setSelectedSorter(
          data?.productSearchResult?.sorters.find(
            (sorter: SearchSorter) => sorter.selected
          )
        );
        dispatchLoading('remove');
      });
    }
  };

  const clearFilter = () => {
    setFilter(undefined);
    setFacetFilters(undefined);

    const url = window.location.href.replace(window.location.search, '');
    window.history.pushState('search', 'Search Page', url);

    initSearch();
  };

  const onFilterChange = (queryString: string) => {
    dispatchLoading('add');
    GetCategoryItems(
      pageId,
      itemsPerLoad,
      requestContext,
      languageRoute,
      undefined,
      undefined,
      selectedSorter?.sortBy,
      selectedSorter?.sortDirection,
      queryString
    ).then((data) => {
      setSearchResult(data);
      setSorters(data?.productSearchResult?.sorters);
      setSelectedSorter(
        data?.productSearchResult?.sorters.find(
          (sorter: SearchSorter) => sorter.selected
        )
      );
      setFilter(queryString);

      const url =
        window.location.href.replace(window.location.search, '') +
        `${queryString ? '?' : ''}${queryString}`;
      window.history.pushState('search', 'Search Page', url);

      dispatchLoading('remove');
    });
  };

  return (
    <main>
      <StructuredCategoryData category={category} />

      {teaserImage && (
        <HeroModule
          header={teaserHeader}
          summary={teaserText}
          image={teaserImage}
          links={teaserLinks}
          headerSize={teaserHeaderSize}
          buttonType={teaserButtonType}
        />
      )}
      <ContentArea childItems={contentAreaTop} />
      <ProductContainer noMargin={!!!searchResult?.categories.length}>
        <Container>
          {searchResult?.categories && !!searchResult?.categories.length && (
            <CategoryBar categories={searchResult?.categories} />
          )}
          <ProductSectionContainer>
            {isDesktop && (
              <>
                <SettingsWrapper>
                  {selectedSorter && (
                    <ProductSorter
                      selectedSorter={selectedSorter}
                      sorters={sorters}
                      onSorterClick={onSorterChange}
                    />
                  )}
                  <GridListToggle onClick={setListView} isListView={listView} />
                </SettingsWrapper>
              </>
            )}
            <FilterProductGrid>
              <FilterContainer>
                {isDesktop && filter && (
                  <ClearFilterText onClick={clearFilter}>
                    {clearFilterText}
                  </ClearFilterText>
                )}
                <FilterBar
                  selectedSorter={selectedSorter}
                  sorters={sorters}
                  onSorterClick={onSorterChange}
                  onFilterChange={onFilterChange}
                  filters={searchResult?.filters}
                  priceValue={priceValue}
                  facetFilters={facetFilters}
                  setFacetFilters={setFacetFilters}
                  currency={searchResult?.productSearchResult?.currency}
                  filterValue={filter}
                  clearFilter={clearFilter}
                />
              </FilterContainer>
              <ProductsContainer>
                <StyledGrid
                  width={GridWidth.ScreenWidth}
                  listViewGrid={listView}
                >
                  {searchResult && !searchResult.productSearchResult && (
                    <NoResultText>{noFilterResultLabel}</NoResultText>
                  )}
                  {searchResult &&
                    !!!searchResult.productSearchResult?.length &&
                    searchResult.productSearchResult?.items?.map(
                      (product: ProductCardModel) => (
                        <ProductCard
                          isListView={listView}
                          key={product.code}
                          item={product}
                          onCategoryPage
                        />
                      )
                    )}
                </StyledGrid>
                {searchResult?.productSearchResult?.availableItems > 0 && (
                  <LoadMoreContainer
                    loaded={loaded}
                    counterText={counterText}
                    loadMore={loadMoreText}
                    disabled={disabled}
                    onClick={() => fetchMoreItems()}
                  />
                )}
              </ProductsContainer>
            </FilterProductGrid>
          </ProductSectionContainer>
        </Container>
      </ProductContainer>
      <ContentArea childItems={contentAreaBottom} />
    </main>
  );
}
const Container = styled('div', {
  px: 0,
  mb: 0,
  w: '100%',
});

const ProductSectionContainer = styled('div', {
  background: '$white',
});

const NoResultText = styled(`div`, {
  gridColumnEnd: 'span 12',
  display: 'flex',
  justifyContent: 'center',
  minHeight: '50vh',
  '@mediaMinLarge': {
    fs: 9,
  },
});

const ClearFilterText = styled(`button`, {
  fontSize: '12px',
  fontWeight: '$fw400',
  lineHeight: '20px',
  letterSpacing: '1px',
  marginBottom: '32px',
});

const FilterContainer = styled(`div`, {
  width: '100%',
  pl: 4,
  '@mediaMinHuge': {
    pl: 0,
    maxWidth: '276px',
  },
});

const SettingsWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: '34px',
  mx: 'auto',
  pb: 4,
  backgroundColor: '$white',
  maxW: '$contentMaxWidth',
  px: 5,
});

const FilterProductGrid = styled('div', {
  display: 'flex',
  g: '20px',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  mx: 'auto',
  backgroundColor: '$white',
  position: 'relative',
  '@mediaMinHuge': {
    px: 5,
    g: '32px',
    flexDirection: 'row',
    maxW: '$contentMaxWidth',
  },
});

const StyledGrid = styled(Grid, {
  g: 8,
  overflow: 'visible',
  margin: 0,
});

const ProductContainer = styled(`div`, {
  mx: 'auto',
  my: 0,
  mt: 6,
  px: 0,
  display: 'flex',
  '@mediaMinHuge': {
    width: '100%',
    maxWidth: `$screenMaxWidth`,
    mt: 12,
  },
  variants: {
    noMargin: {
      true: {
        my: 0,
      },
    },
  },
});

const ProductsContainer = styled(`div`, {
  display: 'flex',
  flexDirection: 'column',
  g: '60px',
  w: '100%',
  px: 4,
  '@mediaMinLarge': {
    px: 5,
  },
  '@mediaMinHuge': {
    px: 0,
  },
});

export default CategoryPage;
