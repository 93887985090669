import Button from '../../Atoms/Buttons/Button';
import Divider from '../../Atoms/Divider/Divider';
import {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
} from '../../Atoms/Typography/Headings/Heading';
import KexLink from '../../Kex/KexLink';
import { styled } from '../../stitches.config';
import IImageModel from '../../Assets/Models/ImageModel.interface';
import TeaserLink from '../../Shared/Models/TeaserLink.interface';

type HeroModuleType = {
  image?: IImageModel;
  header?: string;
  headerSize?: string;
  summary?: string;
  links?: TeaserLink[];
  buttonType?: string;
};

type HeaderObject = {
  [key: string]: any;
};

function HeroModule({
  header,
  headerSize,
  buttonType,
  summary,
  links,
  image,
}: HeroModuleType) {
  const headerComponents: HeaderObject = {
    H1: H1,
    H2: H2,
    H3: H3,
    H4: H4,
    H5: H5,
    H6: H6,
  };
  const Header = headerComponents[headerSize as keyof HeaderObject];

  return (
    <ImageContainer css={{ backgroundImage: `url(${image?.src})` }}>
      <ContentContainer>
        <SummaryContainer>
          <BackDrop>
            <Header color="white">{header}</Header>
            <TextWrapper>
              <StyledPreamble>{summary}</StyledPreamble>
              {links && (
                <ButtonContainer>
                  {links.length > 0 &&
                    links.map((item) => (
                      <KexLink key={item.link?.href} {...item.link}>
                        <Button type={buttonType?.toLowerCase()} size="m">
                          <StyledP>{item.linkText ?? item.link?.text}</StyledP>
                        </Button>
                      </KexLink>
                    ))}
                </ButtonContainer>
              )}
            </TextWrapper>
          </BackDrop>
        </SummaryContainer>
      </ContentContainer>
    </ImageContainer>
  );
}

const ImageContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '500px',
  backgroundColor: 'rgba(24,24,24,0.4)',
  backgroundBlendMode: 'multiply',
});

const ContentContainer = styled('div', {
  display: 'flex',
  wh: '100%',
  maxW: '$contentMaxWidth',
  px: 4,
  justifyContent: 'center',
  '@mediaMinLarge': {
    px: 8,
  },
});

const TextWrapper = styled('div', {
  position: 'relative',
  overflowY: 'hidden',
  '& > p': {
    m: 0,
  },
});

const SummaryContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  w: '100%',
  backgroundColor: 'transparent',
  maxW: 248,
  textAlign: 'center',
  justifyContent: 'center',
});

const BackDrop = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  w: '100%',
  maxW: 248,
  t: 'auto',
  l: 'auto',
});

const ButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  mt: 6,
  g: 4,
  flexWrap: 'wrap',
  '@mediaMinLarge': {
    g: 6,
  },
});

const StyledPreamble = styled('p', {
  m: 0,
  color: '#fff',
  fs: 8,
  fontWeight: '$fw600',
  lineHeight: '24px',
  letterSpacing: '1px',
});

const StyledP = styled('p', {
  m: 0,
});

export default HeroModule;
