import React from 'react';
import { CollectionPage, WithContext } from 'schema-dts';
import CategoryPageModel from '../../CategoryPage/Models/CategoryPageModel.interface';
import { StructuredData } from './StructuredData';

export interface StructuredCategoryDataProps {
  category: CategoryPageModel;
}

/** Renders structured product data using Schema.org vocab */
const StructuredCategoryData: React.FC<StructuredCategoryDataProps> = ({
  category,
}) => {
  const baseCollectionPageData: WithContext<CollectionPage> = {
    '@context': 'https://schema.org',
    '@type': 'CollectionPage',
    name: category.metaTitle,
    description: category.metaDescription,
    primaryImageOfPage: category.openGraph?.imageUrl,
    image: category.openGraph?.imageUrl,
  };

  return <StructuredData json={baseCollectionPageData} />;
};

export { StructuredCategoryData };
