import React, { useRef } from 'react';
import loadable from '@loadable/component';
import { IS_SERVER_CONTEXT } from '../Shared/Configs/EnvConfig';
import useOnScreen from '../Shared/Hooks/useOnScreen';
import KexFallbackBlock from './KexFallbackBlock';

//TODO refactor with correct blocktypes
type PropType = {
  blockType: any;
  isVisible: boolean;
  blockId: any;
};

const loadableComponents: any = {
  StandardBlock: loadable(() => import('../Organisms/Blocks/StandardBlock')),
  ItemListingBlock: loadable(
    () => import('../Organisms/Blocks/ItemListingBlock')
  ),
  ImageLinkListingBlock: loadable(
    () => import('../Blocks/ImageLinkListingBlock/ImageLinkListingBlock')
  ),
  ProductListingBlock: loadable(
    () => import('../Blocks/ProductListingBlock/ProductListingBlock')
  ),
  ImageLinkBlock: loadable(
    () => import('../Shared/Models/ImageLink/ImageLinkBlock')
  ),
  IconBlock: loadable(() => import('../Organisms/Blocks/IconBlock')),
  LinkListBlock: loadable(() => import('../Organisms/Blocks/LinkListBlock')),
  DocumentListBlock: loadable(
    () => import('../Organisms/Blocks/DocumentListBlock')
  ),
  VideoBlock: loadable(() => import('../Organisms/Blocks/VideoBlock')),
  SectionBlock: loadable(() => import('../Organisms/Blocks/SectionBlock')),
  TextBlock: loadable(() => import('../Organisms/Blocks/TextBlock')),
  HeroBlock: loadable(() => import('../Organisms/Blocks/HeroBlock')),
  RichTextBlock: loadable(() => import('../Organisms/Blocks/RichTextBlock')),
};

function KexLoadableBlock(props: PropType) {
  const ref = useRef<HTMLSpanElement>(null);
  const onScreen = useOnScreen({ ref });
  const { blockType, isVisible } = props;
  const LoadableComponent = loadableComponents[blockType];

  return LoadableComponent === undefined ? (
    <React.Fragment />
  ) : isVisible || onScreen || IS_SERVER_CONTEXT ? (
    <LoadableComponent fallback={<KexFallbackBlock />} {...props} />
  ) : (
    <span ref={ref} />
  );
}

export default KexLoadableBlock;
